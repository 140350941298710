.home__container {
  row-gap: 7rem;
}

.home__section{
  margin-top:1rem;
}
.home__data {
  text-align: center;
  margin-top: 3rem;
}

.home__content {
  grid-template-columns: 116px repeat(2, 1fr);
  padding-top: 3.5rem;
  column-gap:rem;
  align-items: center;
}

.home__social {
  display: grid;
  grid-template-columns: max-content;
  row-gap: 1.5rem;
}
.home__social-icon {
  font-size: 2rem;
  color: var(--title-color);
}
.home__scroll-button {
  font-size: 2rem;
  color: var(--title-color);
}

.home__social-icon:hover {
color:#53ccf4;
}
.name-skills {
  color: #20598b;
}

.home__title {
  font-size: var(--big-font-size);
  margin-bottom: var(--mb-0-25);


  
  

}

.home__subtitle {
  /* position: relative; */
  /* font-size: var(--normal-font-size); */
  font-weight: 400;
  /* padding-left: 5rem; */
  /* font-weight: var(--font-normal); */
  margin-bottom: var(--mb-1);
  text-align: center;
  
}



.home__description {
  max-width: 450px;
  margin-bottom: var(--mb-3);
  text-align: center;
  line-height: 2rem;
  word-spacing: 1px;
  /* border: 1px solid red; */
}
.home__img {
  background: url(../../assets/profile.jpg);
  background-repeat: no-repeat;
  /* background-position: center; */
  background-size: cover;
  box-shadow: inset 0 0 0 9px rgb(255, 255, 255 / 30%);
  order: 1;
  /* justify-self: center; */
  width: 350px;
  height: 350px;
  animation: profile 8s ease-in-out infinite 1s;
  border-radius: 50%;
  border: 5px solid#20598b;
  margin-left: 3rem;
}


@keyframes profile {
  0% {
    border-radius: 60% 40% 30% 70% 60% 30% 70% 40%;
  }
  50% {
    border-radius: 30% 60% 70% 40% 50% 60% 30% 60%;
  }
  100% {
    border-radius: 60% 40% 30% 70% 60% 30% 70% 40%;
  }
}

.home__scroll {
  margin-left: 9.25rem;
}
.home__scroll-button {
  width: 20rem;
  height: 5rem;
}

.wheel {
  animation: scroll 2s ease infinite;
}

@keyframes scroll {
  0% {
    transform: translateY(0);
  }
  30% {
    transform: translateY(3.75rem);
  }
}
.home__scroll-name {
  color: var(--title-color);
  font-weight: var(--font-medium);
  margin-right: var(--mb-0-25);
  margin-left: 2px;
  font-size: 0.5rem;
}

/*=============== BREAKPOINTS ===============*/
/* For large devices */
@media screen and (max-width: 992px) {
  .home__content {
    grid-template-columns: 100px repeat(2, 1fr);
    column-gap: 1.25rem;
  }
  .home__hand {
    width: 26px;
    height: 26px;
  }
  .home__subtitle {
    text-align: center;
    padding-left: 4rem;
    margin-bottom: var(--mb-1);
  }
  .home__title {
    font-size: 2.5rem;
    margin-bottom: var(--mb-0-25);

      margin-top: 2rem;
    
   
  
  }


  .home__description {
    max-width: initial;
    margin-bottom: var(--mb-2-5);
  }

  .home__img {
    width: 250px;
    height: 250px;
    box-shadow: inset 0 0 0 9px rgb(255, 255, 255 / 30%);
    /* margin: auto; */
    /* margin-left: 03rem; */
  }

  .home__scroll {
    margin-left: 7.5;
  }
  .home__description {
    max-width: 400px;
    margin-bottom: var(--mb-3);
    text-align: center;
    /* border: 1px solid red; */
  }
  .home__data {
    text-align: center;
    margin-top: .1rem;
  }
  
  .home__social-icon {
    font-size: 3rem;
    color: var(--title-color);
  }

}

@media screen and (max-width: 850px) {

  .home__img {
    /* background: url(../../assets/profile.jpg);
    background-repeat: no-repeat;
    /* background-position: center; */
    /* background-size: cover; */
    /* box-shadow: inset 0 0 0 9px rgb(255, 255, 255 / 30%); */
    /* order: 1; */
    /* justify-self: center; */
    width: 300px;
    height: 300px;
    /* animation: profile 8s ease-in-out infinite 1s; */
    /* border-radius: 2rem; */ 
    /* display: block; */
    margin: auto;
    margin-left: 0.2rem;


  }
  .home__title {
    font-size:2rem;
   
    margin-bottom: var(--mb-0-25);
  }
  
  .home__description {
    max-width: 400px;
    margin-bottom: var(--mb-3);
    text-align: center;
    /* border: 1px solid red; */
  }
  /* .home__subtitle {
    padding-left: 0rem;
    margin-bottom: var(--mb-1);
    font-size: 0.7rem;
  } */
}
/* For medium devices */
@media screen and (max-width: 768px) {
  .home__content {
    grid-template-columns: 1fr 2fr;   
    padding-top: 3.5rem;
  }

  .home__data {
    grid-column: 1/4;
   
      margin-top:.1rem;
    
    
  }
  .home__img {
    box-shadow: inset 0 0 0 6px rgb(255, 255, 255 / 30%);
    width: 200px;
    height: 200px;
  }
  .home__scroll {
    display: none;
  }

  .home__img {
    order: initial;
    justify-self: initial;
  }
  .home__title {
    text-align: center;
    
  }
 
  
  .home__subtitle {

    font-size: var(--h3-font-size);
    margin-left: -3.5rem;
    font-weight: var(--font-normal);
    margin-bottom: var(--mb-1);
    text-align: center;
    z-index: 0;

  }

  .home__subtitle::before {
    display: none;
  }
  .center-button {
    display: block;
    width: 8rem;
    margin: auto;
  }
  .home__img {
    /* background: url(../../assets/profile.jpg);
    background-repeat: no-repeat;
    /* background-position: center; */
    /* background-size: cover; */
    /* box-shadow: inset 0 0 0 9px rgb(255, 255, 255 / 30%); */
    /* order: 1; */
    /* justify-self: center; */
    width: 250px;
    height: 250px;
    /* animation: profile 8s ease-in-out infinite 1s; */
    /* border-radius: 2rem; */ 
    /* display: block; */
    margin: auto;
    margin-left: -4rem;


  }
  .home__description {
    max-width:700px;
    margin-bottom: var(--mb-3);
    text-align: center;
    /* border: 1px solid red; */
  }
  .home__social-icon {
    font-size: 1.5rem;
    color: var(--title-color);
  }
  
}

/* For small devices */
@media screen and (max-width: 400px) {
  .home__img {
    width: 250px;
    height: 250px;
    margin: auto;
    margin-left: -5rem;

  }
  .home__description {
    max-width: 400px;
    margin-bottom: var(--mb-3);
    text-align: center;
    /* border: 1px solid red; */
  }
  .home__social-icon {
    font-size: 1.25rem;
    color: var(--title-color)
  }

  .home__data {
    text-align: center;
    margin-top: .1rem;
  }
  
}

@media screen and (max-width: 270px) {
  .home__img {
    width: 180px;
    height: 180px;
    margin: auto;
    /* margin-left: -3rem; */

  }
  .home__description {
    max-width: 400px;
    margin-bottom: var(--mb-3);
    text-align: center;
    /* border: 1px solid red; */
  }
  .home__social-icon {
    font-size: 1rem;
    color: var(--title-color);
    display: none;
  }
  .home__title {
    margin-top: 5rem;
  }
  .home__data {
    text-align: center;
    margin-top: 1rem;
  }
  
}

