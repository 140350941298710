.tetimonial__container {
  width: 750px;
}
.testimonial__card {
  background-color: var(--container-color);
  border: 1px solid rgba(0,0,0,0.1);
  padding: 1.5rem 2rem;
  border-radius: 1.5rem;
  margin-bottom: var(--mb-3);
}




.testimonial__img {


  width: 800px;
  height: 600px;
  border-radius: 0.7rem;
  /* margin-bottom: 3rem; */
  background-repeat: no-repeat;

 
}

.testimonial__name {
  font-size: var(--normal-font-size);
  font-weight: var(--font-medium);
  margin-bottom: var(--mb-0-25);

  text-align: center;
  color: var(--title-color);


}
.title-div{
  display: flex;
  justify-content: center;
  

}
.image-board{
  margin-top: 1rem;
  
  padding: 0.4rem;
  /* border: 1px solid  var(--title-color); */
  border-radius: 0.8rem;
}
.underline{
  text-decoration: underline;
}

.testimonial__description {
  /* font-size: var(--small-font-size); */
  text-align: center;
}

/* Swiper Class*/
.swiper-pagination-bullet {
  background-color: var(--text-color) !important;
}
.swiper-pagination-bullet-active {
  background-color: var(--title-color) !important;
}

.div-img{
  display: flex;
  flex-direction: column;
  align-items: center;

}


/*=============== BREAKPOINTS ===============*/
/* For large devices */
@media screen and (max-width: 1840px) {
  .image-board{
    margin-bottom: 2rem;
    

  }

}
@media screen and (max-width: 992px) {
  .testimonial__container {
    width: initial;
  }
  .testimonial__card {
    padding: 1.25rem 1.5rem;
  }
  .image-board{
    margin-bottom: 2rem;
    

  }



.testimonial__img {


  width: 800px;
  height: 650px;
  border-radius: 1rem;
  margin-bottom: 3rem;
  background-repeat: no-repeat;
 
}

}

/* For medium devices */
@media screen and (max-width: 768px) {
  .testimonial__img {


    width: 600px;
    height: 475px;
    border-radius: 1rem;
    margin-bottom: 3rem;
    background-repeat: no-repeat;
   
  }
  
}

@media screen and (max-width: 576px) {
  .testimonial__img {


    width: 350px;
    height: 275px;
    border-radius: 1rem;
    margin-bottom: 3rem;
    background-repeat: no-repeat;
   
  }
  
}

/* For small devices */
@media screen and (max-width: 350px) {
  .testimonial__img {


    width: 1000px;
    height: 650px;
    border-radius: 1rem;
    margin-bottom: 3rem;
    background-repeat: no-repeat;
   
  }
  
}