.skills__container {
    grid-template-columns: repeat(3, 350px);
    column-gap: 2rem;
    justify-content: center;
  }
  .skills__content {
    /* background-color: var(--container-color); */
  
    border: 2px solid #2E4C67;
    padding: 2rem 3rem;
    border-radius: 1rem;
  }
  .skills__content:hover {
    transform: scale(1.1);
    transition: 0.25s;
    /* border */
  }
  .mb-main{
    margin-bottom: 2rem;
  }
  
  .testimonial__img {

  width: 2500px;
  height: 100%;
  border-radius: 0.7rem;
  /* margin-bottom: 3rem; */
  background-repeat: no-repeat;

 
}

.cert__container {
    grid-template-columns: repeat(1, 350px);
    column-gap: 2rem;
    justify-content: center;
  }
.certgrid {
    display: grid;
    gap: 5rem;
  }
  .cert__img {


    width: 800px;
    height: 600px;
    border-radius: 0.7rem;
    /* margin-bottom: 3rem; */
    background-repeat: no-repeat;
  
   
  }

 
  .cert__title {
    font-size: var(--normal-font-size);
    font-weight: var(--font-medium);
    text-align: center;
    margin-bottom: var(--mb-0-5);
  }
  
  .skills__box {
    display: flex;
    justify-content: center;
    column-gap: 2.5rem;
  }
  
  .skills_group {
    display: grid;
    align-items: flex-start;
    row-gap: 1rem;
  }
  
  .skills__data {
    display: flex;
    column-gap: 0.5rem;
    margin: 1rem 0;
  }
  .skills .bx-badge-check {
    font-size: 1rem;
    color: var(--title-color);
  }
  
  .skills__name {
    font-size: var(--normal-font-size);
    font-weight: var(--font-medium);
    color: var(--text-color);
  
    /* line-height: 60px; */
  }
  
  /*=============== BREAKPOINTS ===============*/
  /* For large devices */
  @media screen and (max-width: 992px) {
    .skills__container {
      grid-template-columns: max-content;
      row-gap: 2rem;
    }
    .cert__container {
      grid-template-columns: repeat(2, 350px);
      column-gap: 2rem;
      justify-content: center;
    }
  .certgrid {
      display: grid;
      gap: 5rem;
    }
  
  }

  
  
  /* For medium devices */
  
  @media screen and (max-width: 576px) {
    .skills__container {
      grid-template-columns: 1fr;
    }
    .skill__content {
      padding: 1.5rem;
    }
    .cert__container {
      grid-template-columns: repeat(1, 250px);
      column-gap: 1rem;
      justify-content: center;
    }
  .certgrid {
      display: grid;
      gap: .1rem;
    }
   
 .cert__title {
    font-size: var(--normal-font-size);
    font-weight: var(--font-medium);
    text-align: center;
    margin-bottom: var(--mb-0-5);
    margin-top: -2rem;
  }
 
  }
  
  /* For small devices */
  @media screen and (max-width: 350px) {
    .skills__box {
      column-gap: 1.25rem;
    }
    .skills__name {
      font-size: var(--small-font-size);
    }
    .cert__container {
      grid-template-columns: repeat(2, 350px);
      column-gap: 2rem;
      justify-content: center;
    }
  .certgrid {
      display: grid;
      gap: 5rem;
    }
  
  }
  